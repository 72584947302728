import React from "react"
import PropTypes from "prop-types"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"

import { useStaticQuery, graphql } from "gatsby"

const Layout = ({ children, location }) => {
  if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      wpgraphql {
        pageBy(uri: "header-info") {
          title
          headerFooterInfo {
            phoneUrl
            phoneNumber
            emailUrl
            email
            address
            phoneUrlUkraine
            phoneNumberUkraine
            emailUrlUkraine
            emailUkraine
            addressUkraine
            logo {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 120) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }

        menu(id: "TWVudToy") {
          id
          menuItems {
            edges {
              node {
                id
                label
                url

              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <header>
        <Header location={location} data={data} />
      </header>

      <main data-spy="scroll" data-target="#main-navbar" >{children}</main>

      <Footer location={location} data={data} />

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
